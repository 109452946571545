import React, { useEffect, useState } from 'react';
import { Table, Typography, Layout, message, Card, Button, Modal, Input } from 'antd';
import Navbar from '../navbar/Navbar'; // Assuming Navbar is in the correct path
import Utils, { setAuthCredentials } from '../request/Utils';

const { Content } = Layout;
const { Title } = Typography;

const VendorTypeView = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        typeName: '',
        typeCode: '',
        originalTypeName: '',
        originalTypeCode: '',
    });

    // Fetch categories from the backend
    const fetchBusinessCategories = async () => {
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');

        if (username && password) {
            setAuthCredentials(username, password);
        }

        try {
            const response = await Utils.post('/admin/vendorType/view', {
                vendorType: 'getVendor',
            });

            if (response.data && response.data.vendorTypeDetails) {
                setCategories(response.data.vendorTypeDetails);
            } else {
                message.error('Invalid response structure');
            }
        } catch (error) {
            message.error('Failed to fetch business categories');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBusinessCategories();
    }, []);

    // Handle edit button click
    const handleEdit = (vendorType) => {
        setFormData({
            id: vendorType.id,
            typeName: vendorType.typeName,
            typeCode: vendorType.typeCode,
            originalTypeCode: vendorType.typeCode,
            originalTypeName: vendorType.typeName,
        });
        setIsModalVisible(true);
    };

    // Handle form field changes
    const handleFormChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = async () => {
        if (!formData.typeName || !formData.typeCode) {
            message.error('Please fill in all required fields.');
            return;
        }

        const payload = {};
        if (formData.typeName !== formData.originalTypeName) {
            payload.typeName = formData.typeName;
        }
        if (formData.typeCode !== formData.originalTypeCode) {
            payload.typeCode = formData.typeCode;
        }


        try {
            const response = await Utils.put(`/admin/vendorType/update/${formData.id}`, payload);
            console.log(response.data);
            if (response.data && response.data.status === 'success') {

                message.success('Vendor type updated successfully');
                setIsModalVisible(false);
                setFormData({ id: '', typeName: '', typeCode: '' });
                fetchBusinessCategories();
            } else {
                message.error('Failed to update vendor type');
            }
        } catch (error) {
            message.error('Error updating vendor type');
            console.error('Error:', error);
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Type Name',
            dataIndex: 'typeName',
            key: 'typeName',
        },
        {
            title: 'Type Code',
            dataIndex: 'typeCode',
            key: 'typeCode',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Button onClick={() => handleEdit(record)} type="primary">
                    Edit
                </Button>
            ),
        },
    ];

    return (
        <Layout style={{ minHeight: '100vh', backgroundColor: '#f5faff' }}>
            <Navbar /> {/* Navbar added here */}
            <Content style={{ padding: '20px 50px' }}>
                <Title level={2} style={{ textAlign: 'center', color: '#333', marginBottom: '20px' }}>
                    Business Category List
                </Title>
                <Card style={{ padding: '20px', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={categories}
                        rowKey="id"
                        pagination={{ pageSize: 10 }}
                        bordered
                        style={{ backgroundColor: '#fff' }}
                    />
                </Card>
            </Content>

            <Modal
                title="Edit Vendor Type"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={handleSave}
            >
                <label style={{ display: 'block', fontWeight: 'bold' }}>Name:</label>
                <Input
                    name="typeName"
                    value={formData.typeName}
                    onChange={handleFormChange}
                    placeholder="Enter type name"
                    style={{ marginBottom: '10px' }}
                />
                <label style={{ display: 'block', fontWeight: 'bold' }}>Code:</label>
                <Input
                    name="typeCode"
                    value={formData.typeCode}
                    onChange={handleFormChange}
                    placeholder="Enter type code"
                    style={{ marginBottom: '10px' }}
                />
            </Modal>
        </Layout>
    );
};

export default VendorTypeView;
