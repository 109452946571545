import React, { useEffect, useState } from 'react';
import { Table, Typography, Layout, message, Card, Button, Modal, Input, Select } from 'antd';
import Utils, { setAuthCredentials } from '../../request/Utils';
import Navbar from '../../navbar/Navbar';

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const ProductView = () => {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        prodCode: '',
        prodName: '',
        prodComposition: '',
        prodImage: '',
        prodPriceMrp: '',
        catId: '',
        originalProdName: '',
    });

    // Fetch categories
    useEffect(() => {
        const fetchCategories = async () => {
            const username = localStorage.getItem('username');
            const password = localStorage.getItem('password');

            if (username && password) {
                setAuthCredentials(username, password);
            }

            try {
                const response = await Utils.post('/admin/category/view', { demo: 'category' });
                if (response.data?.categoryDetails) {
                    setCategories(response.data.categoryDetails);
                } else {
                    message.error('Invalid response structure');
                }
            } catch (error) {
                message.error('Failed to fetch categories');
            } finally {
                setLoading(false);
                setCategoryLoading(false);
            }
        };

        fetchCategories();
    }, []);

    // Fetch product details
    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                const username = localStorage.getItem('username');
                const password = localStorage.getItem('password');

                if (username && password) {
                    setAuthCredentials(username, password);
                }

                const response = await Utils.post('/admin/product/view', { demo: 'product' });
                if (response.data?.productDetails) {
                    setProducts(response.data.productDetails);
                } else {
                    message.error('Invalid response structure');
                }
            } catch (error) {
                message.error('Failed to fetch products');
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    // Handle edit button click
    const handleEdit = (product) => {
        setFormData({
            id: product.id,
            prodCode: product.proCode,
            prodComposition: product.prodComposition,
            prodImage: '', // Assuming image is not part of the current response
            prodPriceMrp: product.prodPriceMrp,
            catId: product.catId,
            prodName: product.prodName,
            originalProdName: product.prodName,
        });
        setIsModalVisible(true);
    };

    // Handle form field changes
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Handle category change
    const handleCategoryChange = (value) => {
        setFormData((prev) => ({ ...prev, catId: value }));
    };

    // Handle save
    const handleSave = async () => {
        if (!formData.prodCode || !formData.prodName || !formData.prodPriceMrp) {
            message.error('Please fill in all required fields.');
            return;
        }


        const payload = {};
        if (formData.prodName !== formData.originalProdName) {
            payload.prodName = formData.prodName;
        }
        if (formData.prodCode) {
            payload.prodCode = formData.prodCode;
        } if (formData.prodComposition) {
            payload.prodComposition = formData.prodComposition;
        } if (formData.prodPriceMrp) {
            payload.prodPriceMrp = formData.prodPriceMrp;
        } if (formData.catId) {
            payload.catId = formData.catId;
        }

        try {
            const response = await Utils.put(`/admin/product/update/${formData.id}`, payload);
            if (response.data?.status === 'success') {
                message.success('Product updated successfully');
                setIsModalVisible(false);
                // Refresh product list
                const fetchProducts = async () => {
                    const response = await Utils.post('/admin/product/view', { demo: 'product' });
                    if (response.data?.productDetails) {
                        setProducts(response.data.productDetails);
                    }
                };
                fetchProducts();
            } else {
                message.error(response.data?.message || 'Failed to update product');
            }
        } catch (error) {
            message.error('Error updating product');
        }
    };

    // Reset form data on modal close
    const handleCancel = () => {
        setFormData({
            id: '',
            prodCode: '',
            prodName: '',
            prodComposition: '',
            prodImage: '',
            prodPriceMrp: '',
            catId: '',
        });
        setIsModalVisible(false);
    };

    // Table columns
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Product Code',
            dataIndex: 'proCode',
            key: 'proCode',
        },
        {
            title: 'Product Name',
            dataIndex: 'prodName',
            key: 'prodName',
        },
        {
            title: 'Composition',
            dataIndex: 'prodComposition',
            key: 'prodComposition',
        },
        {
            title: 'Price (MRP)',
            dataIndex: 'prodPriceMrp',
            key: 'prodPriceMrp',
            render: (text) => `₹${text.toFixed(2)}`,
        },
        // {
        //     title: 'Category',
        //     dataIndex: 'catId',
        //     key: 'catId',
        //     render: (catId) => categories.find((cat) => cat.catId === catId)?.categoryName || 'N/A',
        // },
        {
            title: 'Category',
            dataIndex: 'categoryName',
            key: 'categoryName',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Button onClick={() => handleEdit(record)} type="primary">
                    Edit
                </Button>
            ),
        },
    ];

    return (
        <Layout style={{ minHeight: '100vh', backgroundColor: '#f5faff' }}>
            <Navbar />
            <Content style={{ padding: '20px 50px' }}>
                <Title level={2} style={{ textAlign: 'center', marginBottom: '20px' }}>
                    Product List
                </Title>
                <Card>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={products}
                        rowKey="id"
                        pagination={{ pageSize: 10 }}
                        bordered
                    />
                </Card>
            </Content>

            <Modal
                title="Edit Product"
                visible={isModalVisible}
                onCancel={handleCancel}
                onOk={handleSave}
            >
                <label style={{ display: 'block', fontWeight: 'bold' }}>Product Code:</label>
                <Input
                    name="prodCode"
                    value={formData.prodCode}
                    onChange={handleFormChange}
                    placeholder="Enter product code"
                    style={{ marginBottom: '10px' }}
                />
                <label style={{ display: 'block', fontWeight: 'bold' }}>Product Name:</label>
                <Input
                    name="prodName"
                    value={formData.prodName}
                    onChange={handleFormChange}
                    placeholder="Enter product name"
                    style={{ marginBottom: '10px' }}
                />
                <label style={{ display: 'block', fontWeight: 'bold' }}>Product Composition:</label>
                <Input
                    name="prodComposition"
                    value={formData.prodComposition}
                    onChange={handleFormChange}
                    placeholder="Enter product composition"
                    style={{ marginBottom: '10px' }}
                />
                <label style={{ display: 'block', fontWeight: 'bold' }}>Product Price:</label>
                <Input
                    name="prodPriceMrp"
                    type="number"
                    value={formData.prodPriceMrp}
                    onChange={handleFormChange}
                    placeholder="Enter product price (MRP)"
                    style={{ marginBottom: '10px' }}
                />
                <label style={{ display: 'block', fontWeight: 'bold' }}>Category Name:</label>
                <Select
                    showSearch
                    value={formData.catId}
                    onChange={handleCategoryChange}
                    placeholder="Select category"
                    loading={categoryLoading}
                    style={{ width: '100%' }}
                >
                    {categories.map((category) => (
                        <Option key={category.id} value={category.id}>
                            {category.categoryName}
                        </Option>
                    ))}
                </Select>
            </Modal>
        </Layout>
    );
};

export default ProductView;
