import { Form, Input, Button, message, Select, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import Utils, { setAuthCredentials } from '../../request/Utils';
import Navbar from '../../navbar/Navbar';
import { useEffect, useState } from 'react';

const CategoryAdd = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [typeCode, setTypeCode] = useState('');
    const [loading, setLoading] = useState(false);

    // Fetch business categories when the component mounts
    useEffect(() => {
        const fetchBusinessCategories = async () => {
            setLoading(true);
            const username = localStorage.getItem('username');
            const password = localStorage.getItem('password');

            if (username && password) {
                setAuthCredentials(username, password);
            }

            try {
                const response = await Utils.post('/admin/vendorType/view', {
                    vendorType: 'getVendor',
                });

                if (response.data && response.data.vendorTypeDetails) {
                    setCategories(response.data.vendorTypeDetails);
                } else {
                    message.error('Invalid response structure');
                }
            } catch (error) {
                message.error('Failed to fetch business categories');
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBusinessCategories();
    }, []);

    // Handle form submission
    const handleSubmit = async (values) => {
        try {
            const response = await Utils.post('/admin/category/save', values);
            console.log(response.data);
            message.success('Category saved successfully!');
            form.resetFields();
            navigate('/category/list');
        } catch (error) {
            message.error('Error saving category: ' + (error.response?.data || error.message));
        }
    };

    return (
        <div className="category-add-container">
            <Navbar />
            <h2 style={{textAlign:'center'}}>Add Category</h2>
            <Row
                gutter={[16, 16]}
                justify="center"
                style={{ maxWidth: '1200px', margin: '0 auto', padding: '10px' }}
            >
                {/* Responsive grid for the form */}
                <Col xs={24} sm={20} md={16} lg={12}>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                style={{ maxWidth: 600, margin: '0 auto' }}
            >
                <Form.Item
                    label="Category Name"
                    name="categoryName"
                    rules={[{ required: true, message: 'Please input the category name!' }]}
                >
                    <Input placeholder="Enter Category Name" />
                </Form.Item>

                <Form.Item
                    label="Categories Col"
                    name="categoriesCol"
                    rules={[{ required: true, message: 'Please input the category Col!' }]}
                >
                    <Input placeholder="Enter Category Col" />
                </Form.Item>

                <Form.Item
                    label="Merchant Type"
                    name="merTypeCode"
                    rules={[{ required: true, message: 'Please select a user type!' }]}
                >
                    <Select
                        placeholder="Select Merchant type"
                        value={typeCode}
                        onChange={(value) => setTypeCode(value)}
                        loading={loading}
                        showSearch
                        filterOption={(input, option) =>
                            option?.children.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {categories.map((category, index) => (
                            <Select.Option key={index} value={category.typeCode}>
                                {category.typeName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Category Image URL" name="categoryImageUrl">
                    <Input placeholder="Enter Image URL" />
                </Form.Item>

                <Form.Item  style={{textAlign:'center'}}>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
            </Col>
            </Row>
        </div>
    );
};

export default CategoryAdd;
