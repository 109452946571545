import React from 'react';
import { Line, Bar } from 'react-chartjs-2';
import './AdminDashboard.css';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Navbar from '../navbar/Navbar';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AdminDashboard = () => {
    // Data for Sales and Purchase Graph
    const salesPurchaseData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [
            {
                label: 'Sales',
                data: [100000, 150000, 120000, 200000, 180000, 250000],
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
            },
            {
                label: 'Purchase',
                data: [80000, 100000, 90000, 150000, 120000, 170000],
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: true,
            },
        ],
    };

    // Data for Income and Expense Graph
    const incomeExpenseData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [
            {
                label: 'Income',
                data: [150000, 170000, 160000, 190000, 220000, 240000],
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
            {
                label: 'Expense',
                data: [50000, 70000, 80000, 90000, 95000, 100000],
                backgroundColor: 'rgba(255, 159, 64, 0.5)',
            },
        ],
    };

    return (
        <div className="dashboard-container">
          <Navbar />

            <h1 className='heading-admin'>Admin Dashboard</h1>
            
            {/* Cards Section */}
            <div className="cards-container">
                <div className="card">
                    <h3>Total Sales</h3>
                    <p>₹1,000,000</p>
                </div>
                <div className="card">
                    <h3>Total Purchase</h3>
                    <p>₹500,000</p>
                </div>
                <div className="card">
                    <h3>Total Users</h3>
                    <p>350</p>
                </div>
                <div className="card">
                    <h3>Total Customers</h3>
                    <p>500</p>
                </div>
            </div>

            {/* Graphs Section */}
            <div className="graphs-container">
                <div className="graph">
                    <h3>Sales and Purchase Graph</h3>
                    <Line data={salesPurchaseData} />
                </div>
                <div className="graph">
                    <h3>Total Income and Expense Graph</h3>
                    <Bar data={incomeExpenseData} />
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
