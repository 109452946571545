import React, { useState } from "react";
import { Row, Col, Form, Input, Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import Utils, { setAuthCredentials } from "../request/Utils";

const { Title } = Typography;

const LoginPage = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const handleLogin = async (values) => {
        const { username, password } = values; // Extract values directly from the form
    
        try {
            const response = await Utils.post('/public/login', { username, password });
    
            const { token, role } = response.data || {};
            if (!token || !role) {
                throw new Error('Invalid response from server');
            }
    
            setAuthCredentials(username, password);
    
            localStorage.setItem('token', token);
            localStorage.setItem('role', role);
            localStorage.setItem('username', username);
            localStorage.setItem('password', password);
    
            // Navigate based on role
            switch (role) {
                case 'ADMIN':
                    navigate('/admin-dashboard');
                    break;
                case 'VENDOR':
                    navigate('/vendor-dashboard');
                    break;
                case 'MERCHANT':
                    navigate('/merchant-dashboard');
                    break;
                default:
                    setErrorMessage('Unknown role: Unable to navigate');
            }
        } catch (error) {
            console.error('Login error:', error.response?.data || error.message);
            setErrorMessage('Invalid username or password');
        }
    };
    

    return (
        <div className="login-page">
            <Row gutter={[16, 16]} align="middle" style={{ height: "100vh" }}>
                {/* Image Column */}
                <Col xs={24} sm={12} className="login-image">
                    <img
                        src="https://img.freepik.com/free-vector/invoice-concept-illustration_114360-2322.jpg"
                        alt="Login Visual"
                        style={{ maxWidth: "100%", height: "auto" }}
                    />
                </Col>

                {/* Form Column */}
                <Col xs={24} sm={12} className="login-form-container">
                    <div style={{ maxWidth: "400px", margin: "0 auto" }}>
                        <Title level={2} style={{ textAlign: "center" }}>
                            Login
                        </Title>
                        <Form
                            layout="vertical"
                            onFinish={handleLogin}
                            className="login-form"
                        >
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[
                                    { required: true, message: "Please enter your username!" },
                                ]}
                            >
                                <Input
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    { required: true, message: "Please enter your password!" },
                                ]}
                            >
                                <Input.Password
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.Item>

                            {errorMessage && (
                                <p className="error-message" style={{ color: "red" }}>
                                    {errorMessage}
                                </p>
                            )}

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login-button"
                                    block
                                >
                                    Login
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default LoginPage;
