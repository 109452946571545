import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Select, Layout, message } from 'antd';
import Utils, { setAuthCredentials } from '../request/Utils';
import { useNavigate } from 'react-router-dom';
import Navbar from '../navbar/Navbar';

const { Content } = Layout;

const MerchantAdd = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [vendorDetails, setVendorDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch vendor data from backend
  const fetchVendorData = async () => {
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    
    if (username && password) {
      setAuthCredentials(username, password);
    }

    setLoading(true);
    try {
      const response = await Utils.post('/admin/vendor/view', { demo: 'vendor' });
      if (response?.data?.vendorDetails) {
        setVendorDetails(response.data.vendorDetails);
      } else {
        message.error('Vendor data failed to load');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Unable to load vendor data');
    } finally {
      setLoading(false);
    }
  };

  // Fetch vendor data on component mount
  useEffect(() => {
    fetchVendorData();
  }, []);

  // Handle form submission
  const handleSubmit = async (values) => {
    const { merName, merGstNo, merDrug1, merDrug2, username, password, vendorCode } = values;

    if (!merName || !merGstNo || !merDrug1 || !merDrug2 || !username || !password || !vendorCode) {
      message.error('All fields are required');
      return;
    }

    try {
      const response = await Utils.post('/vendor/merchant/save', {
        merchant: {
          merDrug1,
          merDrug2,
          merName,
          merGstNo,
        },
        username,
        password,
        vendorCode,
      });

      if (response?.data?.status === 'success') {
        message.success('Merchant added successfully');
        form.resetFields();
        navigate('/merchant/list');
      } else {
        message.error('Error while saving merchant');
      }
    } catch (error) {
      console.error('Unexpected Error:', error);
      message.error('Unexpected error occurred');
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Navbar />
      <Content style={{ padding: '20px' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Merchant</h2>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          style={{ maxWidth: '700px', margin: 'auto' }}
        >
          {/* Merchant Name & GST Number */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Merchant Name"
                name="merName"
                rules={[{ required: true, message: 'Please enter the merchant name' }]}
              >
                <Input placeholder="Enter merchant name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="GST Number"
                name="merGstNo"
                rules={[{ required: true, message: 'Please enter GST number' }]}
              >
                <Input placeholder="Enter GST number" />
              </Form.Item>
            </Col>
          </Row>

          {/* Drug 1 & Drug 2 */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Drug 1"
                name="merDrug1"
                rules={[{ required: true, message: 'Please enter Drug 1' }]}
              >
                <Input placeholder="Enter Drug 1" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Drug 2"
                name="merDrug2"
                rules={[{ required: true, message: 'Please enter Drug 2' }]}
              >
                <Input placeholder="Enter Drug 2" />
              </Form.Item>
            </Col>
          </Row>

          {/* Username & Password */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: 'Please enter a username' }]}
              >
                <Input placeholder="Enter username" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please enter password' }]}
              >
                <Input.Password placeholder="Enter password" />
              </Form.Item>
            </Col>
          </Row>

          {/* Vendor Select Dropdown */}
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Form.Item
                label="Vendor Type"
                name="vendorCode"
                rules={[{ required: true, message: 'Please select vendor type' }]}
              >
                <Select
                  loading={loading}
                  placeholder="Select vendor type"
                  showSearch
                  allowClear
                >
                  {vendorDetails.map((vendor, index) => (
                    <Select.Option key={index} value={vendor.vendorCode}>
                      {vendor.vendorName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Submit Button */}
          <Button
            type="primary"
            htmlType="submit"
            block
            style={{ marginTop: '20px' }}
          >
            Add Merchant
          </Button>
        </Form>
      </Content>
    </Layout>
  );
};

export default MerchantAdd;
