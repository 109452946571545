import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Drawer, Button, Collapse } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { Panel } = Collapse;

// Vendor Menu
const VendorMenu = () => (
  <Menu mode="vertical" theme="light">
    <Menu.Item key="vendor/add">
      <Link to="/vendor/add">Add Vendor</Link>
    </Menu.Item>
    <Menu.Item key="vendor/list">
      <Link to="/vendor/list">View Vendor</Link>
    </Menu.Item>
  </Menu>
);

// Merchant Menu
const MerchantMenu = () => (
  <Menu mode="vertical" theme="light">
    <Menu.Item key="merchant/add">
      <Link to="/merchant/add">Add Merchant</Link>
    </Menu.Item>
    <Menu.Item key="merchant/list">
      <Link to="/merchant/list">View Merchants</Link>
    </Menu.Item>
  </Menu>
);

// Business Categories Menu
const BusinessCategoriesMenu = () => (
  <Menu mode="vertical" theme="light">
    <Menu.Item key="business/add">
      <Link to="/business/add">Add Business</Link>
    </Menu.Item>
    <Menu.Item key="business/list">
      <Link to="/business/list">View Business</Link>
    </Menu.Item>
  </Menu>
);

// Items Menu
const ItemsMenu = () => (
  <Menu mode="vertical" theme="light">
    <SubMenu title="Product">
      <Menu.Item key="product/add">
        <Link to="/product/add">Add Product</Link>
      </Menu.Item>
      <Menu.Item key="product/list">
        <Link to="/product/list">View Product</Link>
      </Menu.Item>
    </SubMenu>
    <SubMenu title="Category">
      <Menu.Item key="category/add">
        <Link to="/category/add">Add Category</Link>
      </Menu.Item>
      <Menu.Item key="category/list">
        <Link to="/category/list">View Categories</Link>
      </Menu.Item>
    </SubMenu>
  </Menu>
);

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Handle window resize
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      {/* Mobile Menu */}
      {isMobile ? (
        <>
          <Button
            type="text"
            icon={<MenuOutlined />}
            onClick={toggleMobileMenu}
            style={{ fontSize: '24px', marginLeft: '10px' }}
          />
          <Drawer
            title="Mobile Menu"
            placement="left"
            onClose={() => setMobileMenuOpen(false)}
            visible={isMobileMenuOpen}
            style={{ overflow: 'auto' }}
          >
            <Collapse defaultActiveKey={[]} style={{ marginBottom: '10px' }}>
              {/* Vendor Menu */}
              <Panel header="Vendor" key="vendor">
                <VendorMenu />
              </Panel>

              {/* Merchant Menu */}
              <Panel header="Merchant" key="merchant">
                <MerchantMenu />
              </Panel>

              {/* Business Categories Menu */}
              <Panel header="Business Categories" key="business">
                <BusinessCategoriesMenu />
              </Panel>

              {/* Items Menu */}
              <Panel header="Items" key="items">
                <ItemsMenu />
              </Panel>
            </Collapse>
          </Drawer>
        </>
      ) : (
        <div
          style={{
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            padding: '10px',
          }}
        >
          {/* Desktop Menu */}
          <Menu mode="horizontal" theme="light">
            {/* Vendor Menu */}
            <Menu.SubMenu title="Vendor">
              <VendorMenu />
            </Menu.SubMenu>
            {/* Merchant Menu */}
            <Menu.SubMenu title="Merchant">
              <MerchantMenu />
            </Menu.SubMenu>
            {/* Business Categories Menu */}
            <Menu.SubMenu title="Business Categories">
              <BusinessCategoriesMenu />
            </Menu.SubMenu>
            {/* Items Menu */}
            <Menu.SubMenu title="Items">
              <ItemsMenu />
            </Menu.SubMenu>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default Navbar;
