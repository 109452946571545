import React, { useEffect, useState } from 'react';
import { Table, Typography, Layout, message, Card, Button, Modal, Input } from 'antd';
import Utils, { setAuthCredentials } from '../../request/Utils';
import Navbar from '../../navbar/Navbar';

const { Content } = Layout;
const { Title } = Typography;

const CategoryView = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        categoryName: '',
        categoriesCol: '',
        originalCategoryName: '', 
    });

    const fetchCategories = async () => {
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');

        if (username && password) {
            setAuthCredentials(username, password);
        }

        try {
            const response = await Utils.post('/admin/category/view', { demo: 'category' });
            if (response.data?.categoryDetails) {
                setCategories(response.data.categoryDetails);
            } else {
                message.error('Invalid response structure');
            }
        } catch (error) {
            message.error('Failed to fetch categories');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    // Handle edit button click
    const handleEdit = (category) => {
        setFormData({
            id: category.id,
            categoryName: category.categoryName,
            categoriesCol: category.categoriesCol,
            originalCategoryName: category.categoryName, // Track original value
        });
        setIsModalVisible(true);
    };

    // Handle form field changes
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Handle save
    const handleSave = async () => {
        if (!formData.categoriesCol) {
            message.error('Please fill in all required fields.');
            return;
        }

        // Create payload by comparing current values with the original values
        const payload = {};
        if (formData.categoryName !== formData.originalCategoryName) {
            payload.categoryName = formData.categoryName;
        }
        if (formData.categoriesCol) {
            payload.categoriesCol = formData.categoriesCol;
        }

        try {
            const response = await Utils.put(`/admin/category/update/${formData.id}`, payload);
            if (response.data?.status === 'success') {
                message.success('Category updated successfully');
                setIsModalVisible(false);
                fetchCategories(); // Refresh the category list
            } else {
                message.error(response.data?.message || 'Failed to update category');
            }
        } catch (error) {
            message.error('Error updating category');
            console.error('Error:', error);
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Category Name',
            dataIndex: 'categoryName',
            key: 'categoryName',
        },
        {
            title: 'Category Col',
            dataIndex: 'categoriesCol',
            key: 'categoriesCol',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Button onClick={() => handleEdit(record)} type="primary">
                    Edit
                </Button>
            ),
        },
    ];

    return (
        <Layout style={{ minHeight: '100vh', backgroundColor: '#f5faff' }}>
            <Navbar />
            <Content style={{ padding: '20px 50px' }}>
                <Title level={2} style={{ textAlign: 'center', marginBottom: '20px' }}>
                    Category List
                </Title>
                <Card>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={categories}
                        rowKey="id"
                        pagination={{ pageSize: 10 }}
                        bordered
                    />
                </Card>
            </Content>

            <Modal
                title="Edit Category"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={handleSave}
            >
                <label style={{ display: 'block', fontWeight: 'bold' }}>Category Name:</label>
                <Input
                    name="categoryName"
                    value={formData.categoryName}
                    onChange={handleFormChange}
                    placeholder="Enter category name"
                    style={{ marginBottom: '10px' }}
                />

                <label style={{ display: 'block', fontWeight: 'bold' }}>Categories Col:</label>
                <Input
                    name="categoriesCol"
                    value={formData.categoriesCol}
                    onChange={handleFormChange}
                    placeholder="Enter category column"
                />
            </Modal>
        </Layout>
    );
};

export default CategoryView;
