import axios from 'axios';

// Create an Axios instance with a base URL
const Utils = axios.create({
    baseURL: 'https://admin.vanigargal.com',
    // baseURL: 'https://wp-store-backend.onrender.com',
    // baseURL: 'http://localhost:8080',
    headers: {
        'Content-Type': 'application/json',
    },
});

// Function to set Basic Authentication headers
export const setAuthCredentials = (username, password) => {
    Utils.defaults.headers.common['Authorization'] = `Basic ${btoa(`${username}:${password}`)}`;
};

export default Utils;
