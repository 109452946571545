import { Form, Input, Button, message, Row, Col } from 'antd';
import Navbar from '../navbar/Navbar';
import Utils from '../request/Utils';
import { useNavigate } from 'react-router-dom';

const VendorTypeAdd = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const handleSubmit = async (values) => {
        try {
            const response = await Utils.post('/admin/vendorType/save', values);
            console.log(response.data);
            message.success('VendorType saved successfully!');
            form.resetFields();
            navigate('/business/list');
        } catch (error) {
            message.error('Error saving vendor type: ' + (error.response?.data || error.message));
        }
    };

    return (
        <div className="business-add-container">
            <Navbar />
            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Business Categories</h2>
            <Row
                gutter={[16, 16]}
                justify="center"
                style={{ maxWidth: '1200px', margin: '0 auto', padding: '10px' }}
            >
                {/* Responsive grid for the form */}
                <Col xs={24} sm={20} md={16} lg={12}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleSubmit}
                        style={{ maxWidth: '100%' }}
                    >
                        {/* Business Name */}
                        <Form.Item
                            label="Business Name"
                            name="typeName"
                            rules={[{ required: true, message: 'Please input the Business name!' }]}
                        >
                            <Input placeholder="Enter Business name" />
                        </Form.Item>

                        {/* Business Code */}
                        <Form.Item
                            label="Business Code"
                            name="typeCode"
                            rules={[{ required: true, message: 'Please input the Business code!' }]}
                        >
                            <Input placeholder="Enter Business code" />
                        </Form.Item>

                        {/* Submit Button */}
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: '100%', maxWidth: '200px' }}
                            >
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default VendorTypeAdd;
