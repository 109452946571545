import React, { useState, useEffect } from 'react';
import { Table, Layout, message, Button, Modal, Descriptions, Input, Tooltip } from 'antd';
import { EyeOutlined, EditOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Utils, { setAuthCredentials } from '../request/Utils';
import Navbar from '../navbar/Navbar';

const { Content } = Layout;

const MerchantView = () => {
    const [merchantData, setMerchantData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editFormData, setEditFormData] = useState({
        id: '',
        merName: '',
        merGstNo: '',
        merDrug1: '',
        merDrug2: '',
        originalMerName: '',
    });

    const fetchMerchantData = async () => {
        try {
            const username = localStorage.getItem('username');
            const password = localStorage.getItem('password');

            if (username && password) {
                setAuthCredentials(username, password);
            }

            const response = await Utils.post('/vendor/merchant/view', { demo: 'merchant' });
            if (response.data && response.data.merchantDetails) {
                setMerchantData(response.data.merchantDetails);
            } else {
                message.error('Failed to fetch merchant data');
            }
        } catch (error) {
            message.error('Error fetching merchant data');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMerchantData();
    }, []);

    const handleViewDetails = (record) => {
        setSelectedMerchant(record);
    };

    const handleEdit = (record) => {
        setEditFormData({
            id: record.id,
            merName: record.merName,
            merGstNo: record.merGstNo,
            merDrug1: record.merDrug1,
            merDrug2: record.merDrug2,
            originalMerName: record.merName,
        });
        setIsEditModalVisible(true);
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setEditFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSaveEdit = async () => {
        const payload = {};
        if (editFormData.merName !== editFormData.originalMerName) {
            payload.merName = editFormData.merName;
        }
        if (editFormData.merGstNo) {
            payload.merGstNo = editFormData.merGstNo;
        }
        if (editFormData.merDrug1) {
            payload.merDrug1 = editFormData.merDrug1;
        }
        if (editFormData.merDrug2) {
            payload.merDrug2 = editFormData.merDrug2;
        }

        try {
            const response = await Utils.put(`/vendor/merchant/update/${editFormData.id}`, payload);

            if (response.data?.status === 'success') {
                message.success('Merchant updated successfully');
                setIsEditModalVisible(false);
                fetchMerchantData();
            } else {
                message.error(response.data?.message || 'Failed to update merchant');
            }
        } catch (error) {
            message.error('Error updating merchant');
            console.error('Error:', error);
        }
    };

    const handleCloseModal = () => {
        setSelectedMerchant(null);
    };

    const handleToggleStatus = async (id, currentStatus) => {
        const newStatus = currentStatus === 'Active' ? 'InActive' : 'Active';
        try {
            const response = await Utils.put(`/vendor/merchant/update/status/${id}`, {
                merStatus: newStatus,
            });

            if (response.data?.status === 'success') {
                message.success(`Status updated to ${newStatus}`);
                fetchMerchantData(); // Refresh data after update
            } else {
                message.error(response.data?.message || 'Failed to update status');
            }
        } catch (error) {
            message.error('Error updating status');
            console.error('Error:', error);
        }
    };

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Merchant Name',
            dataIndex: 'merName',
            key: 'merName',
        },
        {
            title: 'GST Number',
            dataIndex: 'merGstNo',
            key: 'merGstNo',
        },
        {
            title: 'Merchant Code',
            dataIndex: 'merCode',
            key: 'merCode',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <>
                    <Tooltip title="View Details">
                        <Button
                            type="link"
                            icon={<EyeOutlined />}
                            onClick={() => handleViewDetails(record)}
                        />
                    </Tooltip>

                    <Tooltip title="Edit Vendor">
                        <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => handleEdit(record)}
                        />
                    </Tooltip>

                    <Tooltip title={record.merStatus === 'Active' ? 'Deactivate' : 'Activate'}>
                        <Button
                            type="link"
                            icon={
                                record.merStatus === 'Active' ? (
                                    <CheckCircleOutlined style={{ color: 'green' }} />
                                ) : (
                                    <CloseCircleOutlined style={{ color: 'red' }} />
                                )
                            }
                            onClick={() => handleToggleStatus(record.id, record.merStatus)}
                        />
                    </Tooltip>
                </>
            ),
        },
    ];

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Navbar />
            <Content style={{ padding: '50px' }}>
                <h2 style={{ textAlign: 'center' }}>Merchant Details</h2>
                <Table
                    columns={columns}
                    dataSource={merchantData}
                    loading={loading}
                    rowKey="id"
                    pagination={false}
                    style={{ marginTop: '20px' }}
                />

                {selectedMerchant && (
                    <Modal
                        title="Merchant Details"
                        open={true}
                        onCancel={handleCloseModal}
                        footer={null}
                        width={800}
                    >
                        <Descriptions bordered column={1} size="large">
                            <Descriptions.Item label="Merchant Name">{selectedMerchant.merName}</Descriptions.Item>
                            <Descriptions.Item label="GST Number">{selectedMerchant.merGstNo}</Descriptions.Item>
                            <Descriptions.Item label="Drug 1">{selectedMerchant.merDrug1}</Descriptions.Item>
                            <Descriptions.Item label="Drug 2">{selectedMerchant.merDrug2}</Descriptions.Item>
                            <Descriptions.Item label="Vendor Code">{selectedMerchant.vendorCode}</Descriptions.Item>
                            <Descriptions.Item label="Merchant Code">{selectedMerchant.merCode}</Descriptions.Item>
                            <Descriptions.Item label="Merchant Username">{selectedMerchant.merUsername}</Descriptions.Item>
                            <Descriptions.Item label="User Type">{selectedMerchant.userType}</Descriptions.Item>
                            <Descriptions.Item label="Status">{selectedMerchant.merStatus}</Descriptions.Item>
                            <Descriptions.Item label="Merchant Type">{selectedMerchant.typeName}</Descriptions.Item>
                            <Descriptions.Item label="Vendor Name">{selectedMerchant.vendorName}</Descriptions.Item>
                        </Descriptions>
                    </Modal>
                )}

                <Modal
                    title="Edit Merchant"
                    open={isEditModalVisible}
                    onCancel={() => setIsEditModalVisible(false)}
                    onOk={handleSaveEdit}
                >
                    <label style={{ display: 'block', fontWeight: 'bold' }}>Merchant Name:</label>
                    <Input
                        name="merName"
                        value={editFormData.merName}
                        onChange={handleFormChange}
                        placeholder="Merchant Name"
                        style={{ marginBottom: '10px' }}
                    />
                    <label style={{ display: 'block', fontWeight: 'bold' }}>Merchant Gst:</label>
                    <Input
                        name="merGstNo"
                        value={editFormData.merGstNo}
                        onChange={handleFormChange}
                        placeholder="GST Number"
                        style={{ marginBottom: '10px' }}
                    />
                    <label style={{ display: 'block', fontWeight: 'bold' }}>Drug1:</label>
                    <Input
                        name="merDrug1"
                        value={editFormData.merDrug1}
                        onChange={handleFormChange}
                        placeholder="Drug 1"
                        style={{ marginBottom: '10px' }}
                    />
                    <label style={{ display: 'block', fontWeight: 'bold' }}>Drug2:</label>
                    <Input
                        name="merDrug2"
                        value={editFormData.merDrug2}
                        onChange={handleFormChange}
                        placeholder="Drug 2"
                    />
                </Modal>
            </Content>
        </Layout>
    );
};

export default MerchantView;
