import React, { useState, useEffect } from "react";
import {
    Form,
    Input,
    Button,
    message,
    Select,
    Upload,
    InputNumber,
    Col,
    Row,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Utils, { setAuthCredentials } from "../../request/Utils";
import Navbar from "../../navbar/Navbar";
import { useNavigate } from "react-router-dom";


const { Option } = Select;

const ProductAdd = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        prodCode: "",
        prodName: "",
        prodComposition: "",
        prodImage: "",
        prodPriceMrp: null,
        catId: null,
    });

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchCategories = async () => {
        setLoading(true);
        const username = localStorage.getItem("username");
        const password = localStorage.getItem("password");

        if (username && password) {
            setAuthCredentials(username, password);
        }

        try {
            const response = await Utils.post("/admin/category/view", {
                demo: "category",
            });
            if (response.data?.categoryDetails) {
                setCategories(response.data.categoryDetails); // Setting category details
            } else {
                message.error("Invalid response structure");
            }
        } catch (error) {
            message.error("Failed to fetch categories");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectChange = (value, name) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleImageUpload = (file) => {
        setFormData({ ...formData, prodImage: file.url });
        return false;
    };

    const handleSubmit = async () => {
        if (
            !formData.prodCode ||
            !formData.prodName ||
            !formData.prodComposition ||
            !formData.prodPriceMrp ||
            !formData.catId
        ) {
            message.error("Please fill in all required fields.");
            return;
        }

        try {
            const response = await Utils.post("/admin/product/save", formData);
            if (response.data?.status === "success") {
                message.success("Product added successfully");
                setFormData({
                    prodCode: "",
                    prodName: "",
                    prodComposition: "",
                    prodImage: "",
                    prodPriceMrp: null,
                    catId: null,
                });
                navigate("/product/list")

            } else {
                message.error(response.data?.message || "Failed to add product");
            }
        } catch (error) {
            message.error("Error adding product");
        }
    };

    return (
        <div style={{ padding: "20px" }}>
            <Navbar />
            <div
                style={{
                    maxWidth: "800px",
                    margin: "0 auto",
                    background: "#fff",
                    padding: "20px",
                    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    // borderRadius: "8px",
                }}
            >
                <h2 style={{ textAlign: "center" }}>Add Product</h2>
                <Form layout="vertical" onFinish={handleSubmit}>
                    <Row gutter={16}>
                        {/* Column 1 */}
                        <Col xs={24} sm={12}>
                            <Form.Item label="Product Code" required>
                                <Input
                                    name="prodCode"
                                    value={formData.prodCode}
                                    onChange={handleInputChange}
                                    placeholder="Enter product code"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Product Name" required>
                                <Input
                                    name="prodName"
                                    value={formData.prodName}
                                    onChange={handleInputChange}
                                    placeholder="Enter product name"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Product Composition" required>
                                <Input
                                    name="prodComposition"
                                    value={formData.prodComposition}
                                    onChange={handleInputChange}
                                    placeholder="Enter product composition"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Product Image" required>
                                <Upload
                                    beforeUpload={handleImageUpload}
                                    showUploadList={false}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        Upload Product Image
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Product Price (MRP)" required>
                                <InputNumber
                                    name="prodPriceMrp"
                                    value={formData.prodPriceMrp}
                                    onChange={(value) =>
                                        setFormData({
                                            ...formData,
                                            prodPriceMrp: value,
                                        })
                                    }
                                    min={0}
                                    placeholder="Enter product price (MRP)"
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Category" required>
                                <Select
                                    value={formData.catId}
                                    onChange={(value) =>
                                        handleSelectChange(value, "catId")
                                    }
                                    placeholder="Select category"
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    optionFilterProp="children"
                                >
                                    {categories.map((category) => (
                                        <Option key={category.id} value={category.id}>
                                            {category.categoryName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item style={{ textAlign: "center" }}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Add Product
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default ProductAdd;
