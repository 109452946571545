import React, { useState, useEffect } from 'react';
import { Input, Button, Form, message, Layout, Select, Row, Col } from 'antd';
import Utils, { setAuthCredentials } from '../request/Utils';
import { useNavigate } from 'react-router-dom';
import Navbar from '../navbar/Navbar';

const { Content } = Layout;

const VendorAdd = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [formData, setFormData] = useState({
        vendorName: '',
        merGstNo: '',
        merDrug1: '',
        merDrug2: '',
        username: '',
        password: '',
        userType: '',
    });

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchBusinessCategories = async () => {
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');

        if (username && password) {
            setAuthCredentials(username, password);
        }

        try {
            const response = await Utils.post('/admin/vendorType/view', {
                vendorType: 'getVendor',
            });

            if (response.data?.vendorTypeDetails) {
                setCategories(response.data.vendorTypeDetails);
            } else {
                message.error('Invalid response structure');
            }
        } catch (error) {
            message.error('Failed to fetch business categories');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBusinessCategories();
    }, []);

    

    const handleUserTypeChange = (value) => {
        setFormData({ ...formData, userType: value });
    };

    const handleSubmit = async (values) => {
        if (!values.vendorName || !values.merGstNo || !values.merDrug1 || !values.merDrug2 || !values.username || !values.password || !values.userType) {
            message.error('Please fill in all the fields');
            return;
        }

        const vendorData = {
            vendor: {
                merDrug1: values.merDrug1,
                merDrug2: values.merDrug2,
                vendorName: values.vendorName,
                merGstNo: values.merGstNo,
            },
            username: values.username,
            password: values.password,
            userType: values.userType,
        };

        try {
            const response = await Utils.post('/admin/vendor/save', vendorData);

            if (response.data?.status === 'success') {
                message.success('Vendor added successfully');
                setFormData({
                    vendorName: '',
                    merGstNo: '',
                    merDrug1: '',
                    merDrug2: '',
                    username: '',
                    password: '',
                    userType: '',
                });
                navigate('/vendor/list');
            } else {
                message.error('Failed to add vendor');
            }
        } catch (error) {
            message.error('Error while saving vendor');
            console.error('Error:', error);
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Navbar />
            <Content style={{ padding: '20px' }}>
                <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Venor</h2>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    style={{ maxWidth: '700px', margin: 'auto' }}
                >
                    {/* Merchant Name & GST Number */}
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="Vendor Name"
                                name="vendorName"
                                rules={[{ required: true, message: 'Please enter the Vendor name' }]}
                            >
                                <Input placeholder="Enter Vendor name" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="GST Number"
                                name="merGstNo"
                                rules={[{ required: true, message: 'Please enter GST number' }]}
                            >
                                <Input placeholder="Enter GST number" />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Drug 1 & Drug 2 */}
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="Drug 1"
                                name="merDrug1"
                                rules={[{ required: true, message: 'Please enter Drug 1' }]}
                            >
                                <Input placeholder="Enter Drug 1" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="Drug 2"
                                name="merDrug2"
                                rules={[{ required: true, message: 'Please enter Drug 2' }]}
                            >
                                <Input placeholder="Enter Drug 2" />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Username & Password */}
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[{ required: true, message: 'Please enter a username' }]}
                            >
                                <Input placeholder="Enter username" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Please enter password' }]}
                            >
                                <Input.Password placeholder="Enter password" />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Vendor Select Dropdown */}
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="User Type"
                                name="userType"
                                rules={[{ required: true, message: 'Please select user type' }]}
                            >
                                <Select
                                    value={formData.userType}
                                    onChange={handleUserTypeChange}
                                    placeholder="Select user type"
                                    loading={loading}
                                    showSearch
                                    filterOption={(input, option) => {
                                        return option?.children?.toString().toLowerCase().includes(input.toLowerCase());
                                    }}
                                >
                                    {categories.map((category, index) => (
                                        <Select.Option key={index} value={category.typeCode}>
                                            {category.typeName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Submit Button */}
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        style={{ marginTop: '20px' }}
                    >
                        Add Vendor
                    </Button>
                </Form>
            </Content>
        </Layout>
    );
};

export default VendorAdd;
