import React, { useEffect, useState } from 'react';
import { Table, Layout, message, Button, Modal, Input, Descriptions, Tooltip } from 'antd';
import { EyeOutlined, EditOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Utils, { setAuthCredentials } from '../request/Utils';
import Navbar from '../navbar/Navbar';

const { Content } = Layout;

const VendorView = () => {
    const [vendorData, setVendorData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editFormData, setEditFormData] = useState({
        id: '',
        vendorName: '',
        merGstNo: '',
        merDrug1: '',
        merDrug2: '',
        originalVendorName: '',
    });

    const fetchVendorData = async () => {
        try {
            const username = localStorage.getItem('username');
            const password = localStorage.getItem('password');

            if (username && password) {
                setAuthCredentials(username, password);
            }

            const response = await Utils.post('/admin/vendor/view', {
                demo: 'vendor',
            });

            if (response.data && response.data.vendorDetails) {
                setVendorData(response.data.vendorDetails);
            } else {
                message.error('Failed to fetch vendor data');
            }
        } catch (error) {
            message.error('Error fetching vendor data');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchVendorData();
    }, []);

    const handleViewDetails = (record) => {
        setSelectedVendor(record);
    };

    const handleEdit = (record) => {
        setEditFormData({
            id: record.id,
            vendorName: record.vendorName,
            merGstNo: record.merGstNo,
            merDrug1: record.merDrug1,
            merDrug2: record.merDrug2,
            originalVendorName: record.vendorName,
        });
        setIsEditModalVisible(true);
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setEditFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSaveEdit = async () => {

        const payload = {};
        if (editFormData.vendorName !== editFormData.originalVendorName) {
            payload.vendorName = editFormData.vendorName;
        }
        if (editFormData.merDrug1) {
            payload.merDrug1 = editFormData.merDrug1;
        }
        if (editFormData.merDrug2) {
            payload.merDrug2 = editFormData.merDrug2;
        }
        if (editFormData.merGstNo) {
            payload.merGstNo = editFormData.merGstNo;
        }

        try {
            const response = await Utils.put(`/admin/vendor/update/${editFormData.id}`, payload);

            if (response.data?.status === 'success') {
                message.success('Vendor updated successfully');
                setIsEditModalVisible(false);
                fetchVendorData();
            } else {
                message.error(response.data?.message || 'Failed to update vendor');
            }
        } catch (error) {
            message.error('Error updating vendor');
            console.error('Error:', error);
        }
    };

    const handleCloseModal = () => {
        setSelectedVendor(null);
    };

    const handleToggleStatus = async (id, currentStatus) => {
        const newStatus = currentStatus === 'Active' ? 'InActive' : 'Active';
        try {
            const response = await Utils.put(`/admin/vendor/update/status/${id}`, {
                merStatus: newStatus,
            });

            if (response.data?.status === 'success') {
                message.success(`Status updated to ${newStatus}`);
                fetchVendorData(); // Refresh data after update
            } else {
                message.error(response.data?.message || 'Failed to update status');
            }
        } catch (error) {
            message.error('Error updating status');
            console.error('Error:', error);
        }
    };

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Vendor Name',
            dataIndex: 'vendorName',
            key: 'vendorName',
        },
        {
            title: 'GST Number',
            dataIndex: 'merGstNo',
            key: 'merGstNo',
        },
        {
            title: 'Vendor Code',
            dataIndex: 'vendorCode',
            key: 'vendorCode',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <>
                    <Tooltip title="View Details">
                        <Button
                            type="link"
                            icon={<EyeOutlined />}
                            onClick={() => handleViewDetails(record)}
                        />
                    </Tooltip>

                    <Tooltip title="Edit Vendor">
                        <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => handleEdit(record)}
                        />
                    </Tooltip>

                    <Tooltip title={record.merStatus === 'Active' ? 'Deactivate' : 'Activate'}>
                        <Button
                            type="link"
                            icon={
                                record.merStatus === 'Active' ? (
                                    <CheckCircleOutlined style={{ color: 'green' }} />
                                ) : (
                                    <CloseCircleOutlined style={{ color: 'red' }} />
                                )
                            }
                            onClick={() => handleToggleStatus(record.id, record.merStatus)}
                        />
                    </Tooltip>
                </>
            ),
        },
    ];

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Navbar />
            <Content style={{ padding: '50px' }}>
                <h2 style={{ textAlign: 'center' }}>Vendor Details</h2>
                <Table
                    columns={columns}
                    dataSource={vendorData}
                    loading={loading}
                    rowKey="id"
                    pagination={false}
                    style={{ marginTop: '20px' }}
                />

                {selectedVendor && (
                    <Modal
                        title="Vendor Details"
                        open={true}
                        onCancel={handleCloseModal}
                        footer={null}
                        width={800}
                    >
                        <Descriptions bordered column={1} size="large">
                            <Descriptions.Item label="Vendor Name">{selectedVendor.vendorName}</Descriptions.Item>
                            <Descriptions.Item label="GST Number">{selectedVendor.merGstNo}</Descriptions.Item>
                            <Descriptions.Item label="Drug 1">{selectedVendor.merDrug1}</Descriptions.Item>
                            <Descriptions.Item label="Drug 2">{selectedVendor.merDrug2}</Descriptions.Item>
                            <Descriptions.Item label="Vendor Code">{selectedVendor.vendorCode}</Descriptions.Item>
                            <Descriptions.Item label="Vendor Username">{selectedVendor.vendorUsername}</Descriptions.Item>
                            <Descriptions.Item label="User Type">{selectedVendor.userType}</Descriptions.Item>
                            <Descriptions.Item label="Status">{selectedVendor.merStatus}</Descriptions.Item>
                            <Descriptions.Item label="Vendor Type">{selectedVendor.typeName}</Descriptions.Item>
                        </Descriptions>
                    </Modal>
                )}

                <Modal
                    title="Edit Vendor"
                    open={isEditModalVisible}
                    onCancel={() => setIsEditModalVisible(false)}
                    onOk={handleSaveEdit}
                >
                    <label style={{ display: 'block', fontWeight: 'bold' }}>Vendor Name:</label>
                    <Input
                        name="vendorName"
                        value={editFormData.vendorName}
                        onChange={handleFormChange}
                        placeholder="Vendor Name"
                        style={{ marginBottom: '10px' }}
                    />
                    <label style={{ display: 'block', fontWeight: 'bold' }}>Gst NO:</label>
                    <Input
                        name="merGstNo"
                        value={editFormData.merGstNo}
                        onChange={handleFormChange}
                        placeholder="GST Number"
                        style={{ marginBottom: '10px' }}
                    />
                    <label style={{ display: 'block', fontWeight: 'bold' }}>Drug1:</label>
                    <Input
                        name="merDrug1"
                        value={editFormData.merDrug1}
                        onChange={handleFormChange}
                        placeholder="Drug 1"
                        style={{ marginBottom: '10px' }}
                    />
                    <label style={{ display: 'block', fontWeight: 'bold' }}>Drug2:</label>
                    <Input
                        name="merDrug2"
                        value={editFormData.merDrug2}
                        onChange={handleFormChange}
                        placeholder="Drug 2"
                    />
                </Modal>
            </Content>
        </Layout>
    );
};

export default VendorView;
