import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/authentication/Login';
import AdminDashboard from './components/dashboard/AdminDashboard';
import VendorTypeAdd from './components/vendor/VendorTypeAdd';
import VendorTypeView from './components/vendor/VendorTypeView';
import VendorAdd from './components/vendor/VendorAdd';
import VendorView from './components/vendor/VendorView';
import MerchantAdd from './components/merchant/MerchantAdd';
import MerchantView from './components/merchant/MerchantView';
import CategoryAdd from './components/items/category/CategoryAdd';
import CategoryView from './components/items/category/CategoryView';
import ProductAdd from './components/items/product/ProductAdd';
import ProductView from './components/items/product/ProductView';

function App() {
  return (
    <Router>
      <Routes>
        
        <Route path="/" element={<Login />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />

        <Route path="/business/add" element={<VendorTypeAdd />} />
        <Route path="/business/list" element={<VendorTypeView />} />

        <Route path="/vendor/add" element={<VendorAdd />} />
        <Route path="/vendor/list" element={<VendorView />} />

        <Route path="/merchant/add" element={<MerchantAdd />} />
        <Route path="/merchant/list" element={<MerchantView />} />

        <Route path="/category/add" element={<CategoryAdd />} />
        <Route path="/category/list" element={<CategoryView />} />

        <Route path="/product/add" element={<ProductAdd />} />
        <Route path="/product/list" element={<ProductView />} />

      </Routes>
    </Router>
  );
}

export default App;
